var render = function render(){
  var _vm$classroomData$cla;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "classrooms-card"
  }, [_c('a', {
    staticClass: "lnk-classrooms-image"
  }, [_c('img', {
    staticClass: "img-classrooms",
    attrs: {
      "src": "https://cdn2.wuwow.tw/images/wuwow_junior_material_default.svg"
    }
  }), _vm.show_consultant_img && _vm.classroomData.consultants ? _c('img', {
    staticClass: "img-consultant",
    attrs: {
      "src": _vm.classroomData.consultants.image
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "classrooms-card-body"
  }, [_vm.classroom_type === 'academyTalks' ? _c('academy-talks-classroom-info') : _c('vip-classroom-info', {
    attrs: {
      "classroom_type": _vm.classroom_type,
      "classroomData": _vm.classroomData
    }
  }), _c('div', {
    staticClass: "classrooms-divider"
  }), _vm.classroom_type === 'academyTalks' || _vm.classroom_type === 'bookNow' || _vm.classroom_type === 'normal' ? [_c('div', {
    staticClass: "are-classrooms-control m-0"
  }, [_c('div', {
    staticClass: "blk-classrooms-control w-100 text-center"
  }, [_vm.classroom_type === 'academyTalks' ? _c('academy-talks-enter-classroom') : _vm._e(), _vm.classroom_type === 'bookNow' ? _c('plus-classroom') : _vm._e(), _vm.classroom_type === 'normal' ? _c('enter-classroom', {
    attrs: {
      "classroomData": _vm.classroomData
    }
  }) : _vm._e()], 1)])] : _vm.classroom_type === 'evaluations' ? [_c('div', {
    staticClass: "are-classrooms-control"
  }, [_c('div', {
    staticClass: "blk-classrooms-control"
  }, [_c('direct-to-consultant-feedback', {
    attrs: {
      "type": 'evaluations',
      "classroomData": _vm.classroomData
    }
  })], 1), _c('div', {
    staticClass: "blk-classrooms-control"
  }, [_vm.role === _vm.student ? _c('direct-to-evaluations-class', {
    attrs: {
      "action": 'add',
      "classroomData": _vm.classroomData
    }
  }) : _vm._e()], 1)])] : _vm.classroom_type === 'records' ? [_c('div', {
    staticClass: "are-classrooms-control"
  }, [_c('div', {
    staticClass: "blk-classrooms-control",
    class: [_vm.classroomData.class_room.apply_attach ? 'blk-classrooms-control-col4' : 'blk-classrooms-control-col3']
  }, [_c('direct-to-consultant-feedback', {
    attrs: {
      "type": 'records',
      "classroomData": _vm.classroomData
    }
  })], 1), _c('div', {
    staticClass: "blk-classrooms-control",
    class: [_vm.classroomData.class_room.apply_attach ? 'blk-classrooms-control-col4' : 'blk-classrooms-control-col3']
  }, [_c('download-class-record', {
    attrs: {
      "classroomVideo": _vm.classroomData.class_room.video,
      "classroomId": _vm.classroomData.class_room.id,
      "vipId": _vm.classroomData.vip_id,
      "notAllowDownload": _vm.notAllowDownload(_vm.classroomData.class_type_id)
    }
  })], 1), _c('div', {
    staticClass: "blk-classrooms-control",
    class: [_vm.classroomData.class_room.apply_attach ? 'blk-classrooms-control-col4' : 'blk-classrooms-control-col3']
  }, [_vm.isViewEvaluation(_vm.classroomData.class_time, _vm.classroomData.class_room.evaluation) ? _c('direct-to-view-evaluations', {
    class: (_vm$classroomData$cla = _vm.classroomData.class_room.evaluation) !== null && _vm$classroomData$cla !== void 0 ? _vm$classroomData$cla : 'lnk-disabled',
    attrs: {
      "classroomData": _vm.classroomData
    }
  }) : _vm.role === _vm.student ? _c('direct-to-evaluations-class', {
    attrs: {
      "action": _vm.hasEvaluation(_vm.classroomData.class_time, _vm.classroomData.class_room.evaluation),
      "classroomData": _vm.classroomData
    }
  }) : _vm._e()], 1), _vm.classroomData.class_room.apply_attach ? _c('div', {
    staticClass: "blk-classrooms-control blk-classrooms-control-col4"
  }, [_c('download-attach-file', {
    attrs: {
      "classAttachInfo": _vm.classroomData.class_room.apply_attach[0],
      "vipId": _vm.classroomData.vip_id,
      "notAllowDownload": _vm.notAllowDownload(_vm.classroomData.class_type_id)
    }
  })], 1) : _vm._e()])] : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }