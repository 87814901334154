<template lang="pug">
  .classrooms-card
    a.lnk-classrooms-image
      img.img-classrooms(src="https://cdn2.wuwow.tw/images/wuwow_junior_material_default.svg")
      //- class type = 6(百家書苑) 才顯示
      img.img-consultant(v-if='show_consultant_img && classroomData.consultants' :src='classroomData.consultants.image')
    .classrooms-card-body
      academy-talks-classroom-info(v-if="classroom_type === 'academyTalks'")
      vip-classroom-info(v-else :classroom_type='classroom_type' :classroomData="classroomData")
      //- 按鈕分隔線
      .classrooms-divider
      //- 按鈕們
      //- 百家書苑/
      template(v-if="classroom_type === 'academyTalks' || classroom_type === 'bookNow' || classroom_type === 'normal'")
        .are-classrooms-control.m-0
          .blk-classrooms-control.w-100.text-center
            academy-talks-enter-classroom(v-if="classroom_type === 'academyTalks'")
            plus-classroom(v-if="classroom_type === 'bookNow'")
            enter-classroom(v-if="classroom_type === 'normal'" :classroomData="classroomData")
      //- 填寫評鑑
      template(v-else-if="classroom_type === 'evaluations'")
        .are-classrooms-control
          .blk-classrooms-control
            direct-to-consultant-feedback(:type="'evaluations'" :classroomData="classroomData")
          .blk-classrooms-control
            direct-to-evaluations-class(v-if="role === student" :action="'add'" :classroomData="classroomData")
      //- 學習軌跡
      template(v-else-if="classroom_type === 'records'")
        .are-classrooms-control
          .blk-classrooms-control(:class="[classroomData.class_room.apply_attach ? 'blk-classrooms-control-col4':'blk-classrooms-control-col3']")
            direct-to-consultant-feedback(:type="'records'" :classroomData="classroomData")
          .blk-classrooms-control(:class="[classroomData.class_room.apply_attach ? 'blk-classrooms-control-col4':'blk-classrooms-control-col3']")
            download-class-record(:classroomVideo="classroomData.class_room.video" :classroomId="classroomData.class_room.id" :vipId="classroomData.vip_id" :notAllowDownload="notAllowDownload(classroomData.class_type_id)")
          .blk-classrooms-control(:class="[classroomData.class_room.apply_attach ? 'blk-classrooms-control-col4':'blk-classrooms-control-col3']")
            //- 查看評鑑(超過課程開始時間24.5HR)
            direct-to-view-evaluations(v-if="isViewEvaluation(classroomData.class_time, classroomData.class_room.evaluation)" :classroomData="classroomData" :class="classroomData.class_room.evaluation ?? 'lnk-disabled'")
            //- 新增或編輯評鑑(在課程開始時間24.5HR內 且有填過=>編輯 沒填過=>新增)
            direct-to-evaluations-class(v-else-if="role === student" :action="hasEvaluation(classroomData.class_time, classroomData.class_room.evaluation)" :classroomData="classroomData")
          .blk-classrooms-control.blk-classrooms-control-col4(v-if="classroomData.class_room.apply_attach")
            download-attach-file(:classAttachInfo="classroomData.class_room.apply_attach[0]" :vipId="classroomData.vip_id" :notAllowDownload="notAllowDownload(classroomData.class_type_id)")
</template>

<script>
import moment from 'moment';
import academyTalksClassroomInfo from '@/components/class/vipClassroom/academyTalksClassroomInfo';
import academyTalksEnterClassroom from '@/components/class/vipClassroom/academyTalksEnterClassroom';
import vipClassroomInfo from '@/components/class/vipClassroom/vipClassroomInfo';
import plusClassroom from '@/components/class/vipClassroom/plusClassroom';
import enterClassroom from '@/components/class/vipClassroom/enterClassroom';
import directToEvaluationsClass from '@/components/class/vipClassroom/directToEvaluationsClass';
import directToConsultantFeedback from '@/components/class/vipClassroom/directToConsultantFeedback';
import downloadClassRecord from '@/components/class/vipClassroom/downloadClassRecord';
import directToViewEvaluations from '@/components/class/vipClassroom/directToViewEvaluations';
import downloadAttachFile from '@/components/class/vipClassroom/downloadAttachFile';
import commonConstants from '@/constants/common';
import classroomConstants from '@/constants/classroom';

import { mapState } from 'vuex';

export default {
  name: 'VipClassroom',

  components: {
    academyTalksClassroomInfo,
    academyTalksEnterClassroom,
    vipClassroomInfo,
    plusClassroom,
    enterClassroom,
    directToEvaluationsClass,
    directToConsultantFeedback,
    downloadClassRecord,
    directToViewEvaluations,
    downloadAttachFile,
  },

  props: {
    // eslint-disable-next-line
    show_consultant_img: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    // eslint-disable-next-line
    classroom_type: {
      type: String,
      default: function() {
        return 'normal';
      },
    },

    classroomData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      student: commonConstants.ROLE.STUDENT,
    };
  },

  computed: {
    ...mapState({
      role: state => state.auth.role,
    }),
  },

  methods: {
    isViewEvaluation(classTime) {
      const limit_time = moment(classTime).add(1, 'days').add(30, 'minutes');
      return (moment().isAfter(limit_time));
    },

    hasEvaluation(classTime, evaluation){
      return (moment().isBefore(moment(classTime).add(1, 'days').add(30, 'minutes')) && evaluation === null) ? 'add' : 'edit';
    },

    notAllowDownload(classType){
      // DEMO課程
      const notAllowDownloadType = [
        classroomConstants.BOOK_CLASS.ONE_ON_ONE_DEMO,
        classroomConstants.BOOK_CLASS.JUNIOR_ONE_ON_ONE_DEMO,
      ];
      return notAllowDownloadType.includes(classType);
    },
  },

};
</script>
<style scoped>
.lnk-disabled {
  color: #777;
  pointer-events: none;
}
</style>
