import companyConstant from '@/constants/company';

export default {
  messages: {
    tw: {
      delayGetInMes: '已經超過進入教室時間，如有任何問題請來電' + companyConstant.INFO.CONTACT_PHONE + '，謝謝！',
      remainTimeMessage: '等等！還要再{time}才能進入教室唷！',
    },
    en: {
      delayGetInMes: 'It has exceeded the time to enter the classroom. If you have any questions, please call ' + companyConstant.INFO.CONTACT_PHONE + ', thank you!',
      remainTimeMessage: '{time} left to enter the classroom!！',
    },
  },
};
