<template>
  <a href="javascript:;" @click="onRedirectViewEvaluation()">
    <font-awesome-icon icon="search-plus" /> {{ $t("layout.class_evaluation") }}
  </a>
</template>
<script>
export default {
  name: 'DirectToViewEvaluations',

  props: {
    // eslint-disable-next-line
    classroomData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  methods: {
    onRedirectViewEvaluation() {
      this.$store.dispatch('classroom/setSpecificClassroomBasicInfo', this.classroomData);
      this.$route.meta.keepAlive = true;
      this.$router.push({ name: 'viewEvaluation' });
    },
  },
};
</script>
