<template>
  <a href="javascript:;" :class="onEvaluationEnable(classroomData)" @click="onRedirectAddEvaluation()">
    <font-awesome-icon icon="edit" />
    <span v-if="action === 'add'">{{ $t("evaluation.write_evaluation") }}</span>
    <span v-else-if="action === 'edit'">{{ $t("evaluation.edit_evaluation") }}</span>
  </a>
</template>
<script>
const moment = require('moment');
import classroomConstant from '@/constants/classroom';
export default {
  name: 'DirectToEvaluationsClass',

  props: {
    // eslint-disable-next-line
    classroomData: {
      type: Object,
      default: function() {
        return {};
      },
    },

    action: {
      type: String,
      default: function() {
        return 'add';
      },
    },
  },

  methods: {
    onRedirectAddEvaluation() {
      this.$store.dispatch('classroom/setSpecificClassroomBasicInfo', this.classroomData);
      this.$route.meta.keepAlive = true;
      this.$router.push({ name: 'addEvaluation' });
    },

    onEvaluationEnable(classroom) {
      const SHOW = 'show';
      const canEvaluationTime = moment().isBefore(moment(classroom.class_time).add(1, 'days'));
      const notLectureClass = classroom.class_type_id !== classroomConstant.BOOK_CLASS.LECTURE;
      const studentIsShow = classroom.vip_status === SHOW;
      return (canEvaluationTime && notLectureClass && studentIsShow) ? '' : 'lnk-disabled';
    },
  },
};
</script>
