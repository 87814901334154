<template lang="pug">
  div
    a.lnk-classrooms-title
      //- 標題與教材標題 cls.title.substring(0, 15) + " - " +  cls.material.title
      h2.m-0.h2-classrooms-title material title
    br
    h5.lnk-classrooms-title.mt-3.text-danger note
    .are-classrooms-info
      .blk-classrooms-info
        //- {{ cls.class_time_start.substring(0, 16) + ' ~ ' + cls.class_time_end.substring(0, 16) }}
        font-awesome-icon(icon='calendar-minus' size='2x')
        | 2022/02/23 15:00 ~ 2022/02/25 15:00
      .blk-classrooms-info
        //- {{ cls.sign_time_start.substring(0, 16) + ' ~ ' + cls.sign_time_end.substring(0, 16) }}
        font-awesome-icon(icon='user-plus' size='2x')
        | 2022/02/15 15:00 ~ 2022/02/23 15:00
      .blk-classrooms-info
        a(class="a-color")
          font-awesome-icon(icon='user' size='2x')
          | data.class_room.consultants
          | .english_name
      .blk-classrooms-info
        font-awesome-icon(icon='ticket-alt' size='2x')
        //- {{ point }}
        | 2
      .blk-classrooms-info
        font-awesome-icon(icon='users' size='2x')
        //- {upper_limit}
        | 100
</template>

<script type="text/javascript">
export default {
  name: 'AcademyTalksClassroomInfo',

  components: {},

  props: {
    // eslint-disable-next-line
    classroom_type: {
      type: String,
      default: function() {
        return 'normal';
      },
    },
  },

  data() {
    return {};
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {},
};
</script>

<style>
.a-color a {
  color: #2255b1;
}
</style>
