<template>
  <div class="blk-classrooms-control blk-classrooms-control-col4">
    <a :class="notAllowDownload ? 'lnk-disabled' : '' " href="javascript:;" @click="onDownloadFiles()">
      <i class="fas fa-file-download" />{{ $t('download_files') }}
    </a>
  </div>
</template>
<script>
import LioshutanApi from '@/api';
import { alertMessage } from '@/utils/sweetAlert.js';

export default {
  name: 'DownloadAttachFile',

  props: {
    // eslint-disable-next-line
    classAttachInfo: {
      type: Object,
      default: function() {
        return {};
      },
    },
    // eslint-disable-next-line
    vipId: {
      type: Number,
      default: function() {
        return 0;
      },
    },
    notAllowDownload: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },

  methods: {

    async onDownloadFiles(){
      const params = {
        uuid: this.classAttachInfo.uuid,
        vi: this.vipId,
      };
      if (this.notAllowDownload){
        return;
      }
      try {
        const res = await LioshutanApi.vip.getClassAttachFile(params);
        window.open(res.data.data.download_url);
      } catch (err) {
        alertMessage(this.$t('failed'), 'error', err);
      }
    },
  },
};
</script>
