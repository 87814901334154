<template lang="pug">
  div
    a(:class="notAllowDownload ? 'lnk-disabled' : '' " :id="'lnk-videoList-' + classroomId" href="javascript:;")
      font-awesome-icon(icon="caret-square-right")
      | {{ $t("class_records.video_download") }}
    b-popover(
      :target="'lnk-videoList-' + classroomId"
      placement="top"
      :title="$t('class_records.video_download')"
    )
      b-list-group(v-if="classroomVideo.length > 0")
        b-list-group-item(v-for="(video, v_index) in classroomVideo" :key="video.id")
          a(href="javascript:;" @click='downloadVideos(classroomId)')
            font-awesome-icon(icon="download")
            | {{ 'Video ' + (v_index + 1) }}
      template(v-else) {{ $t("class_records.not_yet_uploaded") }}

</template>
<script>
import lioshutanApi from '@/api';
import { alertHtmlMessage } from '@/utils/sweetAlert.js';
import commonConstants from '@/constants/common';
export default {
  name: 'DownloadClassRecord',

  props: {
    classroomVideo: {
      type: Array,
      default: function() {
        return [];
      },
    },

    classroomId: {
      type: Number,
      default: function() {
        return 0;
      },
    },

    vipId: {
      type: Number,
      default: function() {
        return 0;
      },
    },

    notAllowDownload: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },

  methods: {
    async downloadVideos(classroomId){
      const params = {
        classroomId: classroomId,
        data: {
          platform: commonConstants.CURRENT_PLATFORM,
          vi: this.vipId,
        },
      };
      try {
        const response = await lioshutanApi.vip.getClassVideo(params);
        if (response.data.message === 'OK'){
          window.location.href = response.data.data.download_url;
        } else {
          alertHtmlMessage('失敗', 'error', '1.錄影檔下載失敗<br>2.檔案已超過下載期限無法下載', this.$t('confirm'));
        }
      } catch (err) {
        alertHtmlMessage('失敗', 'error', '1.錄影檔下載失敗<br>2.檔案已超過下載期限無法下載', this.$t('confirm'));
      }
    },
  },
};
</script>
