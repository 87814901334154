<template lang="pug">
  div
    a.lnk-classrooms-title
      //- class type = 6(百家書苑) 顯示教室title(class_room.title) 其餘教材title(class_room.material.title)
      h2.m-0.h2-classrooms-title
        a(:href="classroomData.class_room.material.file" :class="classroomData.class_room.material.download_flag ? '' : 'disabled-link'")
          span(v-if="classroom_type === 'academyTalks'") {{ classroomData.class_room.title }}
          span(v-else) {{ classroomData.class_room.material.title }}
    .are-classrooms-info
      .row
        .blk-classrooms-info.col-12.col-md-6
          font-awesome-icon(icon='calendar-minus')
          |  {{ getDateWithTimeMinute(classroomData.class_time) }}
        .blk-classrooms-info.col-12.col-md-6
          div(v-if="!isDemo(classroomData.class_type_id) && getCanOrderClass"  class="a-color")
            router-link(:to="{ name: 'ConsultantDetail', params: { id: classroomData.class_room.consultants.id }}")
              //- 超連結顧問行事曆 /dojo/consultants/:id
              font-awesome-icon(icon='user')
              | {{ classroomData.class_room.consultants.english_name }}
          div(v-else)
            span(class="a-color")
              font-awesome-icon(icon='user')
              | {{ classroomData.class_room.consultants.english_name }}
        .blk-classrooms-info.col-12.col-md-6(v-if="classroom_type !== 'evaluations'")
          font-awesome-icon(icon='book-open')
          span(v-for="(topic, t_index) in classroomData.class_room.material.interested_topics")
            span(v-if="t_index != 0") &nbsp;,
            span {{ topic.title }}
        .blk-classrooms-info.col-12.col-md-6(v-if="classroom_type !== 'evaluations'")
          font-awesome-icon(icon='users')
          //- 後端回傳資料需統一 是否為字串直接顯示 or 1~3 1:1on1 2:1on3 3:Demo
          | {{ classroomData.class_type }}
</template>

<script type="text/javascript">
import { getDateWithTimeMinute } from '@/utils/moment';
import { isDemo } from '@/utils/classroom';
export default {
  name: 'ClassroomInfo',

  components: {},

  props: {
    // eslint-disable-next-line
    classroom_type: {
      type: String,
      default: function() {
        return 'normal';
      },
    },

    classroomData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {};
  },
  computed: {
    getCanOrderClass() {
      return this.$store.getters['vipAccount/getCanOrderClass'];
    },
  },

  methods: {
    isDemo,
    getDateWithTimeMinute(time){
      return getDateWithTimeMinute(time);
    },
  },
};
</script>

<style>
.a-color a {
  color: #2255b1;
}
</style>

<style lang="scss" scoped>
.disabled-link {
  color: #82c1ea;
  pointer-events: none;
  text-decoration: none;
}
</style>
