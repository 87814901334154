var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    class: _vm.onEvaluationEnable(_vm.classroomData),
    attrs: {
      "href": "javascript:;"
    },
    on: {
      "click": function ($event) {
        return _vm.onRedirectAddEvaluation();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "edit"
    }
  }), _vm.action === 'add' ? _c('span', [_vm._v(_vm._s(_vm.$t("evaluation.write_evaluation")))]) : _vm.action === 'edit' ? _c('span', [_vm._v(_vm._s(_vm.$t("evaluation.edit_evaluation")))]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }