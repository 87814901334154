export default {
  messages: {

    en: {
      classFullMessage: 'This classroom is full (please select another triage)',
    },

    tw: {
      classFullMessage: '此教室已滿 (請選擇其他分流)',
    },

  },
};
