<template lang="pug">
div
  // 有被評鑑過
  a#lnk-consultant-feedback(:class="showTeacherAnalysis() ? '' : 'lnk-disabled'" @click="onRedirectAnalysis(classroomData.class_applications)")
    font-awesome-icon(icon="chart-bar")
    | &nbsp;{{ $t('evaluation.consultant_feedback') }}
  b-tooltip(v-if="type === 'evaluations'" target="lnk-consultant-feedback" placement="leftbottom")
    .text-center
      h4 {{ $t('evaluation.overall_level') }}
      button.btn.btn-primary.rounded(type="button")
        | Level
        template(v-if="classroomData.class_room.analysis")
          span.badge.badge-light.rounded {{ classroomData.class_room.analysis.average_level }}
      .blockquote.mt-3 {{$t('evaluation.vip_analysis_suggestion')}}
</template>
<script>
import lioshutanApi from '@/api';
import { alertMessage } from '@/utils/sweetAlert.js';

export default {
  name: 'DirectToConsultantFeedback',

  props: {
    type: {
      type: String,
      default: function() {
        return '';
      },
    },

    classroomData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  methods: {
    async onRedirectAnalysis(class_applications_id) {
      this.$store.dispatch('classroom/setSpecificClassroomBasicInfo', this.classroomData);
      // ***需測試是否需要 > 舊WUWOW會需要從WUWOW軌跡帶入 interested_topics 送到 viewAnalysis 做判斷 (參考lst-taichi-external -> vips_class_records\class_records.vue)
      // this.setInterestedTopics(classroom.class_room.material.interested_topics);
      const params = {
        class_applications_id: class_applications_id,
      };
      try {
        const response = await lioshutanApi.vip.getConsultantAnalysis(params);
        // 回傳有顧問分析資料
        if (response.message === 'OK'){
          this.$store.dispatch('classroom/setSpecificClassroomAnalysisData', response.data.data[0]);
          this.$route.meta.keepAlive = true;
          this.$router.push({ name: 'viewAnalysis' });
        } else {
          alertMessage('失敗', 'error', response.message);
        }
      } catch (error){
        alertMessage('失敗', 'error', error.message);
      }
    },

    showTeacherAnalysis(){
      const allowStatus = ['filled', 'late'];
      return allowStatus.includes((this.classroomData.class_room.analysis || {}).status);
    },
  },
};
</script>
<style scoped>
a {
  cursor: pointer;
}
</style>
