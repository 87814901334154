import moment from 'moment';
import urlConstant from '@/constants/url';
import classroomConstant from '@/constants/classroom';
import commonConstant from '@/constants/common';
import { getDateWithTimeSecond } from '@/utils/moment';
import { setUrlQuery } from '@/utils/index';

// TODO Move 進入教室規則判斷由後端給 line: 9 - 77 By Asa
// TODO Move 取得教室url組合由後端給 line: 80 - 88 By Asa
const seconds = 'seconds';

/**
 * @des 判斷是否可進入當前教室
 * @param {String} openTime: 可進入教室時間, closeTime: 關閉進入教室時間, currentTime: 目前時間
 * @returns {Object} 目前進入教室狀態 status: true(可進入) false(無法進入), message: 回傳訊息
 */
export function enterClassRoom(openTime, closeTime, currentTime) {
  const result = {
    status: false,
    message: { type: '' },
  };
  if (allowEnterClassRoom(openTime, closeTime, currentTime)) {
    result.status = true;
    result.message.type = classroomConstant.ENTER_CLASS_ROOM_TYPE.ALLOW;
  } else {
    if (overTimeEnterClassRoom(closeTime, currentTime)) {
      result.message.type = classroomConstant.ENTER_CLASS_ROOM_TYPE.OVER_TIME;
    } else if (beforeEnterClassRoom(openTime, currentTime)) {
      result.message.type = classroomConstant.ENTER_CLASS_ROOM_TYPE.NOT_YET;
      result.message.remainTime = getRemainTime(openTime, currentTime);
    }
  }
  return result;
}

/**
 * @des 進入教室時間判斷
 * @param  openTime: 可進入教室時間, closeTime: 關閉進入教室時間, currentTime: 目前時間
 * @returns {Boolean} 回傳是否可進入教室
 */
function allowEnterClassRoom(openTime, closeTime, currentTime) {
  const enterOpenTime = getDateWithTimeSecond(openTime),
    enterCloseTime = getDateWithTimeSecond(closeTime),
    enterTime = getDateWithTimeSecond(currentTime);
  return (moment(enterTime).isBetween(enterOpenTime, enterCloseTime));
}

/**
 * @des 超過進入教室
 * @param closeTime: 關閉進入教室時間, currentTime: 目前時間
 * @returns {Boolean} 回傳是否超過進教室時間
 */
function overTimeEnterClassRoom(closeTime, currentTime) {
  const enterCloseTime = getDateWithTimeSecond(closeTime),
    enterTime = getDateWithTimeSecond(currentTime);
  return (moment(enterTime).isAfter(enterCloseTime));
}

/**
 * @des 尚未能進入教室
 * @param openTime: 可進入教室時間, currentTime: 目前時間
 * @returns {Boolean} 回傳是否進教室前時間
 */
function beforeEnterClassRoom(openTime, currentTime) {
  const enterOpenTime = getDateWithTimeSecond(openTime),
    enterTime = getDateWithTimeSecond(currentTime);
  return (moment(enterTime).isBefore(enterOpenTime));
}

/**
 * @des 計算剩餘進入教室時間
 * @param  openTime: 可進入教室時間, currentTime: 目前時間
 * @returns {String} 回傳剩餘時間格式: H:mm:ss
 */
function getRemainTime(openTime, currentTime) {
  const openTimeMoment = moment(openTime);
  let remainTime = openTimeMoment.diff(currentTime, seconds); // 取得目前時間與上課時間差距(秒)
  remainTime = new Date(remainTime * 1000).toISOString().substr(11, 8);
  return remainTime;
}

/**
 * @des 取得教室網址
 * @param classroomId: 教室id,  classToken: 好視通教室token
 * @returns {String} 回傳好視通教室url
 */
export function getClassroomUrl(classroomId, classToken) {
  // ci: 教室 id, token: 好視通教室token
  const classroomUrl = setUrlQuery(process.env.VUE_APP_BASE_API, urlConstant.CLASSROOM.ENTER_CLOUD_CLASSROOM_PATH, { ci: classroomId, access_token: classToken });
  return classroomUrl;
}

/**
 * @des 判斷是短Demo
 * @param classType
 * @returns {Boolean} 回傳是否
 */
export function isShortDemo(classType) {
  return classType === classroomConstant.BOOK_CLASS.SHORT_DEMO;
}

/**
 * @des 判斷是Demo課程
 * @param classType
 * @returns {Boolean} 回傳是否
 */
export function isDemo(classType) {
  const demoClassType = [
    classroomConstant.BOOK_CLASS.ONE_ON_ONE_DEMO,
    classroomConstant.BOOK_CLASS.JUNIOR_ONE_ON_ONE_DEMO,
  ];
  return demoClassType.includes(classType);
}

/**
 * @des 判斷是即時Demo課程
 * @param classType
 * @returns {Boolean} 回傳是否
 */
export function isRealTimeDemo(classType) {
  const realTimeDemoClassType = [
    classroomConstant.BOOK_CLASS.REALTIME_WUWOW_DEMO,
    classroomConstant.BOOK_CLASS.REALTIME_JUNIOR_DEMO,
  ];
  return realTimeDemoClassType.includes(classType);
}

/**
 * @des 判斷是否JR教材
 * @param materialPlatform
 * @returns {Boolean} 回傳是否
 */
export function isJrMaterial(materialPlatform) {
  return materialPlatform === commonConstant.CURRENT_PLATFORM;
}

/**
 * @des 判斷是否JR class
 * @param classType, platform
 * @returns {Boolean} 回傳是否
 */
export function isJrClass(classType, platform) {
  const jrClassType = [
    classroomConstant.BOOK_CLASS.ONE_ON_ONE_DEMO,
    classroomConstant.BOOK_CLASS.CLASS_TYPE_NORMAL,
    classroomConstant.BOOK_CLASS.CLASS_TYPE_SPECIAL,
    classroomConstant.BOOK_CLASS.CLASS_TYPE_SPECIFY_MATERIAL,
    classroomConstant.BOOK_CLASS.JUNIOR_ONE_ON_ONE_DEMO,
  ];
  return (platform === commonConstant.CURRENT_PLATFORM) && jrClassType.includes(classType);
}
