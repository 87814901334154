<template>
  <a href="javascript:;">
    <font-awesome-icon icon="plus" /> {{ $t("class.enter_class") }}
  </a>
</template>
<script>
export default {
  name: 'PlusClassroom',
};
</script>
