var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "javascript:;"
    },
    on: {
      "click": function ($event) {
        return _vm.onRedirectViewEvaluation();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "search-plus"
    }
  }), _vm._v(" " + _vm._s(_vm.$t("layout.class_evaluation")) + " ")], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }