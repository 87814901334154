<template lang="pug">
div
  b-row
    b-col
      a(href="javascript:;" @click="onRedirectEnterClass(classroomData, currentTime, vipType)")
        font-awesome-icon.mr-2(icon="sign-in-alt")
        | {{ $t("class.enter_class") }}
        //- 2020-01-19 臨時更新
    //- 學員後台家長進入按鈕隱藏
    //- b-col
    //-   b-row.pr-2
    //-     b-col
    //-       a(href="javascript:;" @click="onRedirectEnterClass(classroomData, currentTime, parentType)")
    //-         font-awesome-icon.mr-2(icon="sign-in-alt")
    //-         | {{ $t("class.parents_enter_class") }}
    //-     b-col.select-parent-box
    //-       b-form-select.mt-4(v-model="selectedParent")
    //-         b-form-select-option(v-for="parent in parentsOptions" :key="parent.id" :label="parent.chinese_name" :value="parent.id")
  font.text-danger(style="font-size: 0.875rem") {{ $t("classroom.ios_browser_remind") }}
</template>
<script>
import { alertInfoMessage, alertFailedMessage } from '@/utils/sweetAlert.js';
import classroomConstant from '@/constants/classroom';
import { mapState } from 'vuex';
import { enterClassRoom, getClassroomUrl, isRealTimeDemo } from '@/utils/classroom';
import i18n from '@/components/class/vipClassroom/enterClassroom/lang';

export default {

  name: 'EnterClassroom',

  props: {
    classroomData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  i18n,

  data(){
    return {
      vipType: 'vip',
      parentType: 'parents',
      studentType: 'student', // TODO 擔心 vipType , parentType 還有其他用途，因此先加上這兩個新的。如確定用不到直接刪除即可 By Dobby
      relativesType: 'parent', // TODO 擔心 vipType , parentType 還有其他用途，因此先加上這兩個新的。如確定用不到直接刪除即可 By Dobby
      selectedParent: null,
      parentsOptions: '',
    };
  },

  computed: {
    ...mapState({
      currentTime: state => state.common.currentTime,
    }),
  },

  created(){
    if (this.classroomData.vip_parent !== null){
      this.parentsOptions = (this.classroomData.vip_parent);
      // 預設第一位家長
      this.selectedParent = this.classroomData.vip_parent[0].id;
    }
  },

  methods: {
    onRedirectEnterClass(classroomData, currentTime, roleType){
      // TODO 後續判斷後段給家長可進入時間 By Asa
      const accountRole = localStorage.getItem('account_role');
      if (accountRole === this.relativesType) {
        this.getParentClassroomUrl(classroomData.class_room.id, this.parentsOptions, this.selectedParent);
      } else if (accountRole === this.studentType){
        if (isRealTimeDemo(classroomData.class_type_id)){
          window.open(getClassroomUrl(classroomData.class_room.id, classroomData.class_room.access_token));
        } else {
          const result = enterClassRoom(classroomData.enter_open_time, classroomData.enter_close_time, currentTime, classroomData.class_room.id);
          switch (result.message.type){
            case classroomConstant.ENTER_CLASS_ROOM_TYPE.ALLOW:
              window.open(getClassroomUrl(classroomData.class_room.id, classroomData.class_room.access_token));
              break;
            case classroomConstant.ENTER_CLASS_ROOM_TYPE.OVER_TIME:
              alertFailedMessage(this.$t('delayGetInMes'));
              break;
            case classroomConstant.ENTER_CLASS_ROOM_TYPE.NOT_YET:
              alertInfoMessage(this.$t('remainTimeMessage', { time: result.message.remainTime }));
              break;
          }
        }
      }
    },

    getParentClassroomUrl(classRoomId, parentsOptions, selectedParentId){
      parentsOptions.forEach((parent) => {
        if (parent.id === selectedParentId){
          window.open(getClassroomUrl(classRoomId, parent.access_token));
        }
      });
    },
  },
};
</script>
<style scoped>
.select-parent-box{
  position: relative;
  left: -50px;
}
</style>
