var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('br'), _c('h5', {
    staticClass: "lnk-classrooms-title mt-3 text-danger"
  }, [_vm._v("note")]), _c('div', {
    staticClass: "are-classrooms-info"
  }, [_c('div', {
    staticClass: "blk-classrooms-info"
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "calendar-minus",
      "size": "2x"
    }
  }), _vm._v("2022/02/23 15:00 ~ 2022/02/25 15:00")], 1), _c('div', {
    staticClass: "blk-classrooms-info"
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "user-plus",
      "size": "2x"
    }
  }), _vm._v("2022/02/15 15:00 ~ 2022/02/23 15:00")], 1), _c('div', {
    staticClass: "blk-classrooms-info"
  }, [_c('a', {
    staticClass: "a-color"
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "user",
      "size": "2x"
    }
  }), _vm._v("data.class_room.consultants .english_name")], 1)]), _c('div', {
    staticClass: "blk-classrooms-info"
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "ticket-alt",
      "size": "2x"
    }
  }), _vm._v("2")], 1), _c('div', {
    staticClass: "blk-classrooms-info"
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "users",
      "size": "2x"
    }
  }), _vm._v("100")], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "lnk-classrooms-title"
  }, [_c('h2', {
    staticClass: "m-0 h2-classrooms-title"
  }, [_vm._v("material title")])]);

}]

export { render, staticRenderFns }