<template lang="pug">
  div
    //- v-if='cls.number_of_apply >= cls.upper_limit'
    a.disable-click( href='javascript:;')
      b-alert(show='' variant='danger') {{ $t('classFullMessage') }}
    //- v-else-if='now >= cls.class_time_start && now <= cls.class_time_end'
    a( v-if="false" href='javascript:;')
      i.fa.fa-plus
      | {{ $t('class.enter_class') }}
    //- v-else-if='now >= cls.sign_time_start && now <= cls.sign_time_end' 訂課加入課程
    a( v-if="false" href='javascript:;')
      i.fa.fa-plus
      | {{ $t('class.enter_class') }}
    //- v-else
    a.disable-click( v-if="false" href='javascript:;')
      i.fa.fa-times
      | {{ $t('class.enter_class') }}
</template>
<script>
import i18n from './lang';
export default {
  name: 'AcademyTalksEnterClassroom',

  i18n,
};
</script>

<style lang="scss" scoped>
.disable-click{
  pointer-events: none;
  color: #ccc;
  cursor: not-allowed;
}
</style>
